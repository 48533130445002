











import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import SozialMediaFlipCard from "./SozialMediaFlipCard.vue"

@Component({
  components: {
    SozialMediaFlipCard,
  }
})export default class Skills extends Vue {
  // @Prop() private msg!: string;

  private socialMediaIcons: Array<Object> = [
    {
      color: "white",
      background: "#dd4b39",
      icon: "fa-google",
      link: "",
    },
    {
      color: "white",
      background: "#007bb5",
      icon: "fa-linkedin",
      link: ""
    },
  ]

  mounted(){}

  get getYears() {
    var now = moment(new Date()); //todays date
    var end = moment("2000-06-07"); // another date
    var duration = moment.duration(now.diff(end));
    return duration.asYears().toFixed(0);
  }


}
