<template>
  <div>
    <div id="home">
      <Startsite />
    </div>
    <div id="aboutme">
      <AboutMe />
    </div>
    <div id="skills">
      <Skills/>
    </div>
    <div id="jobs">
      <!-- <Startsite/> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Startsite from "@/components/Startsite.vue";
import AboutMe from "@/components/AboutMe.vue"
import Skills from "@/components/Skills.vue"

export default {
  name: "Home",
  components: {
    Startsite,
    AboutMe,
    Skills
  },
};
</script>
