
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const common = namespace('Common')

@Component
export default class SozialMediaFlipCard extends Vue {
  @Prop() private CardData!: Object;

  // Getter
  @common.Getter
  public getIsMobile! : Boolean; 

  mounted(){
    console.log("Hallo")
    console.log(this.CardData)
  }
}

