import { VuexModule, Module, Mutation, Action,  } from 'vuex-module-decorators'

@Module({ namespaced: true })
class Common extends VuexModule{
  public isMobile: boolean = false;
  
  public get getIsMobile() {
    // return (param: string) => { return this.isMobile }; // With Params
    return this.isMobile;
  }

  @Mutation
  public setDevice(isMobile: boolean): void {
    this.isMobile = isMobile;
  }

  @Action
  public updateDevice(isMobile: boolean): void {
    this.context.commit('setDevice', isMobile)
  }
}
export default Common