import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueScrollto from 'vue-scrollto'
// import VueMobileDetection from 'vue-mobile-detection';

// Vue.use(VueMobileDetection);
Vue.use(vueScrollto)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
