































import moment from "moment";

import SozialMediaFlipCard from "./SozialMediaFlipCard.vue"

import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const common = namespace('Common')

Vue.component('SozialMediaFlipCard', SozialMediaFlipCard);


@Component({
  components: {
    SozialMediaFlipCard,
  }
}) export default class AboutMe extends Vue {
  // @Prop() private msg!: string;
  private deviceHeight: Object = {"min-height" : window.screen.height + "px"};
  // Data
  private socialMediaIcons: Array<Object> = [
    {
      color: "white",
      background: "#dd4b39",
      icon: "fa-google",
      link: "mailto:delano-bruggmann@hotmail.com",
    },
    {
      color: "white",
      background: "#007bb5",
      icon: "fa-linkedin",
      link: "https://www.linkedin.com/in/delano-bruggmann-3a3a06158/"
    },
  ]

  // Getter
  @common.Getter
  public getIsMobile! : boolean; 

  // Computed
  // get isMobile(): boolean {
  //   var test =  this.$store.getters.isMobileDevice();
  //   console.log("test ", test);
  //   return test;
  // }

  get getYears(): string {
    var now = moment(new Date()); //todays date
    var end = moment("2000-06-07"); // another date
    var duration = moment.duration(now.diff(end));
    return duration.asYears().toFixed(0);
  }

  mounted(){}



}
