

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavBarMobile extends Vue {

    private navOpen: boolean = false;

    get getRight(): number {
      return -window.innerWidth;
    }

    toggleNav(): void {
      this.navOpen = !this.navOpen;
    }
}
