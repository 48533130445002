























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class'
const common = namespace('Common')

// https://www.npmjs.com/package/mobile-device-detect
import { isMobile } from "mobile-device-detect";
import NavBar from "@/components/NavBar/NavBar.vue";
import NavBarMobile from "@/components/NavBar/NavBarMobile.vue";

@Component({
  components: {
    NavBar,
    NavBarMobile
  }
})
export default class App extends Vue {
  
  // private showNav: boolean = false;

  // private deviceHeight: Number = screen.height;

  created(){
    this.updateDevice(((window.innerWidth <= 700) || isMobile));
  }

  mounted(){
    window.addEventListener('resize', this.handleResize);
  }

  // Map Actions
  @common.Action
  public updateDevice!: (isMobile: boolean) => void

  // Map Getter
  @common.Getter
  public getIsMobile! : boolean; 

  // handleNavBarScroll(event: Event) : void {
  //   this.showNav =  window.scrollY > (window.innerHeight / 2);
  // }

  handleResize(event: Event) : void{
    this.updateDevice(((window.innerWidth <= 700) || isMobile));
  }
}
