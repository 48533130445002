













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavBar extends Vue {
    
  private showNav: boolean = false;

  mounted(){
    window.addEventListener('scroll', this.handleNavBarScroll);
  }

  destroyed(){
    window.removeEventListener('scroll', this.handleNavBarScroll);
  }

  handleNavBarScroll(event: Event) : void {
    this.showNav =  window.scrollY > (window.innerHeight / 2);
  }
}
