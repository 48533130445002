















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class'

const common = namespace('Common')

@Component
export default class Startseite extends Vue {
  // @Prop() private msg!: string;
  private deviceHeight: Object = {"min-height" : window.screen.height + "px"};

    // Getter
  @common.Getter
  public getIsMobile! : boolean; 

}
